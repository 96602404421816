import React from "react"; 
import { Link, useMatch, useResolvedPath } from "react-router-dom"
import Logo  from '../src/assets/better_logo.jpg' 
import qrcode from '../src/assets/qrcodev2.jpg'
 

const Footer = () => {
    return(
        <footer className="flex">
        <div class="container">
          <ul>
            <li><a className="font-bold">Behrend-Dach GmbH</a></li>
            <li><a >Dachdeckermeister Daniel Bartnik</a></li>
            <li><a >24306 Plön - Lübecker Str. 4</a></li>
            <li><a >Telefon: 04522 802 960</a></li>
            <li><a >Email: info@behrend-dach.de</a></li>
            <li><a >Designed by : <a href="https://jeremiah-coder.web.app/"><img alt=""  className="add"  src={Logo} /></a></a></li>
            <li><a><Link to="/Impressum" >
        <button  >Impressum</button>
        </Link><div className="w-[20px]"></div>
        <Link to="/Datenschutz">
        <button  >Datenschutz</button>
        </Link></a></li>
          </ul>
        </div>
        <div className="flex-col">
        <img className="scale-50 FIX"  src={qrcode}></img>
        <h1 className="text-[30px] desk">oder unter: <a href="https://www.google.com/search?sa=X&sca_esv=209cf1f1455dac22&sca_upv=1&tbm=lcl&sxsrf=ADLYWIK3gA229GWwLzEIGMN1eW7861OpEg:1722947854288&q=Behrend-Dach+GmbH+D%C3%A4cher+-+Fassaden+-+Abdichtungen+Rezensionen&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxI2MjWwMDEzNDA3N7A0NDMxNjY33sDI-IrR3ik1oyg1L0XXJTE5Q8E9N8lDweXwkuSM1CIFXQW3xOLixJTUPCDTMSklMzmjpDQvHcgNSq1KzSvOzM9LzVvESqkJACt0kYmeAAAA&rldimm=2508461077091643373&hl=de-DE&ved=2ahUKEwjr6sj2sOCHAxUM9bsIHbocGZ4Q9fQKegQIJRAF&biw=1920&bih=917&dpr=1#lkt=LocalPoiReviews&lrd=0x47b267fec65a6bc9:0x22cfd80f28f0efed,3,,,," className="bh1 ">Hier</a></h1>
        <h1 className="text-[30px] mob">Bewerten Sie uns gerne hier: <a href="https://www.google.de/search?client=safari&sca_esv=faf1bece9f668766&sca_upv=1&hl=de-de&sxsrf=ADLYWILtM910zT6uLbZ3i8XN3m8tzm13bQ:1724613528452&q=rezensionen%20f%C3%BCr%20behrend-dach%20gmbh%20d%C3%A4cher%20-%20fassaden%20-%20abdichtungen%20pl%C3%B6n&ludocid=2508461077091643373&ibp=gwp%3B0%2C7&sa=X&ved=0CAsQ5foLahcKEwjYsuWG7pCIAxUAAAAAHQAAAAAQBA#lkt=LocalPoiReviews&arid=ChZDSUhNMG9nS0VJQ0FnSUQwdHVYakhnEAE&lpg=cid:CgIgAQ%3D%3D" className="bh1 ">Hier</a></h1>
        </div>
      </footer>
    )
}

export default Footer;

