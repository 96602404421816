import React from 'react';
import Logo from '../assets/Logo.png'
import {TypeAnimation} from 'react-type-animation' 
import {motion} from 'framer-motion' 
import {fadeIn} from '../variants' 
import Dach9 from '../assets/Dach9.webp'
import Dach10 from '../assets/Dach10.webp'

import oldDach from '../assets/oldDach.webp'
import newDach from '../assets/newDach.webp'
import News1 from '../assets/Meisterhaft2024.png'
import News2 from '../assets/Gesel2.png'
import News3 from '../assets/Gesel.jpg'
import {
  ReactCompareSlider,
  ReactCompareSliderHandle,
  ReactCompareSliderImage
} from "react-compare-slider";
import { useInView } from 'react-intersection-observer';
import { Link, useMatch, useResolvedPath } from "react-router-dom"
import roof from '../assets/roofsvg.png'
import Tool from '../assets/tool.png'
import {Ticker1, Ticker2} from './components/Ticker';
import { Helmet } from 'react-helmet';

const Home = ( style, ...props ) => {
  const [ref, InView] = useInView({
    threshold: 0.5,
  });
 
  return(
    <motion.section variants={fadeIn()}  initial="hidden" whileInView={'show'}>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Home</title>
                <link rel="canonical" href="https://www.behrend-dach.de/Home" />
            </Helmet>
    <section className='min-h-[85vh] lg:min-h-[78vh] flex items-center pf bg1 margin-none' id='home'>
    <div className="container mx-auto ">
      <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12 '>
        {/* text */}
        <div className='flex-1 text-center font-secondary lg:text-left'>
          <motion.h1 variants={fadeIn('up', 0.3)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='text-[55px] font-bold leading-[0.8] lg:text-[60px] bh1'>
            GUT BEDACHT AUS PLÖN <span className='bh2 lg:text-[40px]'>BEHREND-DACH IN PLÖN SAGT</span>
            </motion.h1>
            <motion.div variants={fadeIn('up', 0.3)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='mb-6 text-[36px] lg:text-[60px] pf uppercase leading-[1]'>
              <span className='mr-4'>...</span>
              <TypeAnimation sequence={[
                'Moin, Moin',
                2000,
                'Hallo',
                2000,
                'Hi',
                2000
              ]} 
              speed={50} 
              className='pf font-bold'
              wrapper='span'
              repeat={Infinity}
              />
            </motion.div>
            <motion.p variants={fadeIn('up', 0.7)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='mb-8 max-w-lg mx-auto lg:mx-0 font-bold'>
            Hallo und Herzlich Willkommen auf unserer Website. Überzeuge Dich von unserer Qualität und Leistung auf dieser interaktiven Info-Website!
            </motion.p> 
            {/* Kontakt */}

            <Link to="/Kontakt">
            <motion.button variants={fadeIn('up', 0.9)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='btn btn-sm rounded-2xl font-bold text-[26px] borders'>In Kontakt treten</motion.button>
            </Link>
        </div>
          {/* image */}
        <motion.div variants={fadeIn('down', 0.5)} initial="hidden" whileInView={'show'} className='hidden lg:flex flex-1 max-w-[320px] lg:max-w-[482px]'>
           <img src={Logo} alt='' className='movingI'/>
          </motion.div>
      </div>
    </div>
  </section>
    <div className='h-[50px]'></div>
  <div className='container mx-auto margin-right fixit'>
  <div className='flex flex-col  lg:flex-row'>
     
      
     
      
  <div className='flex-1 flex justify-start items-center'>
     
     <motion.img variants={fadeIn('down', 0.3)} initial="hidden" whileInView={'show'} whileHover={{ scale: 1.4 }}whileTap={{ scale: 0.9 }}  src={Dach10} alt='' className='rounded-xl dachimg'/>
    </div><div className='mr-20'></div>
         <motion.div variants={fadeIn('left', 0.2)} initial="hidden" whileInView={'show'} className='glasscard scaleM fixglass shiftM '>
             <div className='  gap-y-6  p-6 items-start' >
             <h2 className='bh2 text-[30px]'>Allgemein</h2>
             <div className='h-[20px]'></div>
             <h1 className='bh1 text-[45px] font-bold'>Unsere Arbeit</h1>
             <div className='h-[40px]'></div>
             <h2 className='pfs  '>Wir beschäftigen uns mit dem gesamten Spektrum des Dachdeckerhandwerks, führen Kleinstreparaturen aus wie das Austauschen einer einzelnen Pfanne, bauen Dachfenster und Photovoltaikanlagen ein, führen aber natürlich auch energetische Sanierungen  im Steil- und Flachdach aus.</h2>
             <div className='h-[20px]'></div>
             <h2 className='pfs'>Auch Blecharbeiten wie Dachentwässerung, Einfassungen und Stehfalz gehören zu unserem Aufgabenspektrum</h2>
             <div className='h-[20px]'></div>
            
             </div>
             
         </motion.div>
      </div>
    </div>
  <div className='container mx-auto mg margin-right fixit'>
    <div className='flex flex-col lg:flex-row '>
      <motion.div variants={fadeIn('right', 0.2)} initial="hidden" whileInView={'show'} className='glasscard scaleM mmalt fixglass rest'>
             <div className=' gap-y-6  p-6 items-start' >
             <h2 className='bh2 text-[30px]'>Nicht ihr gewöhnlicher Dachdecker</h2>
             <div className='h-[20px]'></div>
             <h1 className='bh1 text-[45px] font-bold'>Unsere Qualität</h1>
             <div className='h-[40px]'></div>
             <h2 className='pfs  '>Wir achten auf hohe Qualitätsstandards und verarbeiten hochwertige Produkte unserer<div className='h-[10px]'></div>  <Link to='/Partnerfirmen' className='bh1'><div className=''/> - Partnerfirmen </Link>  </h2>
             <div className='h-[30px]'></div>
             <h2 className='pfs '>Unsere Mitarbeiter nehmen regelmäßig an Schulungen teil</h2>
             </div>
             
         </motion.div>
         </div>
         <div className='flex-1 flex flex-col justify-start items-center'>  
     <motion.img variants={fadeIn('down', 0.3)} initial="hidden" whileInView={'show'} whileHover={{ scale: 1.4 }}whileTap={{ scale: 0.9 }}  src={Dach9} alt='' className='rounded-xl dachimg2 shiftM'/>
     </div>
         </div>
         
         <div className='h-[300px] spc'/>
         <Ticker1/>
      <div className='h-[50px]'></div>
    <Ticker2/>
      
    
  
  <div className='h-[200px]'></div>
  <motion.div variants={fadeIn('up', 0.7)} initial="hidden" whileInView={'show'} >
  <div className='container mx-auto'>
  <h1 className='bh2 text-[28px] h-[70px]'>ㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤÜberzeugen Sie sich selbst</h1>
  <h2 className='bh1 text-[45px] font-bold h-[70px] mobiletext2 '>ㅤㅤmit einem Vorher-Nachher-Effekt einer Sanierung</h2>
  </div>
  
  <ReactCompareSlider className='container mx-auto shadow1' 
      {...props}
      handle={
        <ReactCompareSliderHandle
          buttonStyle={{
            backdropFilter: undefined,
            background: "white",
            border: 0,
            color: "#333"
          }}
        />
      }
      itemOne={
        <ReactCompareSliderImage
          src={oldDach}
          alt="one" 
        />
       
      }
      itemTwo={
        
        <ReactCompareSliderImage
          src={newDach}
          alt="two"
        /> 
      }
      style={{
        display: "flex",
        width: "100%",
        height: "50vh",
        ...style
      }}
    />
    
    <div className='h-[100px]'></div>
    <div className='container mx-auto'>
          <div className='flex flex-col lg:flex-row gap-x-10 '>
            <motion.div 
            
            variants={fadeIn('right', 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}
            className='flex-1 flex flex-col gap-y-12 mb-10 lg:mb-0'>
              {/*text*/}
              <div>
                <h2 className='h2 leading-tight bh1 font-bold text-[78px]'>
                  News <br/>
                  
                </h2>
                <p className='max-w-sm mb-16 pfs'>  ( bewegen Sie die Maus über die Bilder für mehr Info )
                </p>
                <Link to="/News"><p className='max-w-sm mb-16 text-[20px] font-bold bh1'> Mehr </p></Link>
                
              </div>  
              {/*image*/}  
              <div className=''>
               <div className='group relative overflow-hidden border-2 border-black/50  rounded-2xl  '>
                   {/*overlay*/}
                  <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                  {/* img */}
                  <img className='group-hover:scale-125 transition-all duration-500' src={News2} alt='' />
                  {/* prettitle */}
                  <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                   <span className='text-gradient'></span> 
                  </div>
                  {/* title */}
                  <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                    <span className='text-3x1 text-white '></span>
                  </div>
                  <div className='absolute -bottom-full left-80 group-hover:bottom-5 transition-all duration-700 z-50'>
                    
                  </div>
               </div>     
               </div>
              </motion.div>
            <motion.div className='flex-1 flex flex-col gap-y-10' 
            variants={fadeIn('left', 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}
            >
              {/*image*/}
              <div className='shadow rounded-2xl'>
              <div className='group relative overflow-hidden border-2 border-black/50 rounded-xl'>
                   {/*overlay*/}
                  <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                  {/* img */}
                  <img className='group-hover:scale-125 transition-all duration-500' src={News3} alt='' />
                  {/* prettitle */}
                  <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                   <span className='text-gradient'>Wir gratulieren zur bestandenen Gesellenprüfung</span> 
                  </div>
                  {/* title */}
                  <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                    <span className='text-3x1 text-white  '></span>
                  </div>
               </div>
               </div> 
               <div className='group relative overflow-hidden border-2 border-black/50 rounded-xl'>
                   {/*overlay*/}
                  <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                  {/* img */}
                  <img className='group-hover:scale-125 transition-all duration-500' src={News1} alt='' />
                  {/* prettitle */}
                  <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                   <span className='text-gradient'>Urkunde 2024</span> 
                  </div>
                  {/* title */}
                  <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                    <span className='text-3x1 text-white  '></span>
                  </div>
               </div>     
            </motion.div>   
          </div>
        </div>
        <div className='h-[100px]'></div>
        <motion.div variants={fadeIn('up', 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}>
        <h2 className='bh1 text-center text-[45px] font-bold h-[70px] mobiletext2 '>Fördermöglichkeiten für Dachfenster der Firma Roto</h2>
        <a  href="https://www.roto-foerderservice.de/Startseite?action=c6925dfd-9255-415e-a028-fa2d488714cd" target="_blank"><img className='resize2' alt='' src="https://www.roto-foerderservice.de/images/banner2.jpg" /></a>
     </motion.div>
    
    
   
    </motion.div><div className='h-[200px]'></div>
  </motion.section>
  );
}; 

export default Home;

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}

