import React, { useState, useEffect } from 'react';
import News1 from '../assets/Digga.webp';
import News2 from '../assets/Gesel2.png'
import News3 from '../assets/Boecker_Zertifikat_KRANPROFI_2024_Behrend_Florian Fischer.1.png'
import News4 from '../assets/Boecker_Zertifikat_Kranfuehrer_2024_Behrend.1.png'
import News5 from '../assets/Meisterhaft2024.png'
import JP1 from '../assets/JP1.webp'
import JP2 from '../assets/JP2.webp'
import JP3 from '../assets/JP3.webp'
import JP4 from '../assets/JP4.webp'
import News6 from '../assets/Gesel.jpg'
import { Helmet } from 'react-helmet';
import {motion} from 'framer-motion' 
import { fadeIn } from '../variants';

const News = () => {
 
  return (
    <>
    <Helmet>
                <meta charSet="utf-8" />
                <title>News</title>
                <link rel="canonical" href="https://www.behrend-dach.de/News" />
            </Helmet>
    <div className="container mx-auto">
        <div className='flex-1 text-center font-secondary '>
          <motion.div variants={fadeIn('right', 0.3)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='lg:text-[60px] text-[55px] font-bold bh1 leading-[0.8] '>
          Berufliche<br/> Möglichkeiten
            </motion.div>  
        </div>
          {/* image */}
    </div >
    <div className='mt10'></div>
    <div className=' paddt mx-auto '>
    <div className='flex-1 flex flex-mob gap-y-10 mx-10' >
                  <img className='hover:scale-60 scale-50 transition-all duration-500 group relative overflow-hidden border-2 border-black/50 rounded-xl ml-10' src={JP4} alt='' />
              {/*<motion.div variants={fadeIn('right', 0.3)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='group relative overflow-hidden border-2 border-black/50 rounded-xl ml-10 mrg'>
                  <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                 
                  <img className='group-hover:scale-125 transition-all duration-500' src={JP1} alt='' />
                  
                  <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                   <span className='text-gradient'></span> 
                  </div>
                  
                  <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                    <span className='text-3x1 text-white  '></span>
                  </div>
               </motion.div> 
               <motion.div variants={fadeIn('right', 0.5)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='group relative overflow-hidden border-2 border-black/50 rounded-xl mrg'>
                   {/*overlay*
                  <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                  {/* img *
                  <img className='group-hover:scale-125 transition-all duration-500' src={JP2} alt='' />
                  {/* prettitle 
                  <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                   <span className='text-gradient'></span> 
                  </div>
                  {/* title *
                  <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                    <span className='text-3x1 text-white  '></span>
                  </div>
               </motion.div>
               <motion.div variants={fadeIn('left', 0.5)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='group relative overflow-hidden border-2 border-black/50 rounded-xl mrg'>

                  <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                  {/* img *
                  <img className='group-hover:scale-125 transition-all duration-500' src={JP3} alt='' />
                  {/* prettitle *
                  <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                   <span className='text-gradient'></span> 
                  </div>
                  {/* title *
                  <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                    <span className='text-3x1 text-white  '></span>
                  </div>
               </motion.div>
               <motion.div variants={fadeIn('left', 0.3)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='group relative overflow-hidden border-2 border-black/50 rounded-xl mrg'>
                  <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                  {/* img 
                  <img className='group-hover:scale-125 transition-all duration-500' src={JP4} alt='' />
                  {/* prettitle 
                  <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                   <span className='text-gradient'></span> 
                  </div>
                  {/* title 
                  <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                    <span className='text-3x1 text-white  '></span>
                  </div>
               </motion.div>*/}
            </div>
    </div>
  <div className='container mx-auto'>
          <div className='flex flex-col lg:flex-row gap-x-10 '>
            <motion.div 
            
            variants={fadeIn('right', 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}
            className='flex-1 flex flex-col gap-y-12 mb-10 lg:mb-0'>
              {/*text*/}
              <div>
                <h2 className='h2 leading-tight bh1 font-bold text-[78px]'>
                  News <br/>  
                </h2>
                <p className='max-w-sm mb-16 pfs'>  ( bewegen Sie die Maus über die Bilder für mehr Info )
                </p> 
              </div>  
              <div className='h-[50px]'></div>
              <div className='shadow rounded-2xl'>
               <div className='group relative overflow-hidden border-2 border-black/50  rounded-2xl  '>
                   {/*overlay*/}
                  <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                  {/* img */}
                  <img className='group-hover:scale-125 transition-all duration-500' src={News6} alt='' />
                  {/* prettitle */}
                  <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                   <span className='text-gradient'>Wir gratulieren zur bestandenen Gesellenprüfung</span> 
                  </div>
                  {/* title */}
                  <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                  </div>
                  
               </div>     
               </div>
              </motion.div>
            <motion.div className='flex-1 flex flex-col gap-y-10' 
            variants={fadeIn('left', 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}
            >
              {/*image*/}
              <div className='group relative overflow-hidden border-2 border-black/50 rounded-xl shadow '>
                   {/*overlay*/}
                  <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                  {/* img */}
                  <img className='group-hover:scale-125 transition-all duration-500' src={News2} alt='' />
                  {/* prettitle */}
                  <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                   <span className='text-gradient'></span> 
                  </div>
                  {/* title */}
                  <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                   
                  </div>
               </div> 
               <div className='group relative overflow-hidden border-2 border-black/50 rounded-xl'>
                   {/*overlay*/}
                  <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                  {/* img */}
                  <img className='group-hover:scale-125 transition-all duration-500' src={News5} alt='' />
                  {/* prettitle */}
                  <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                   <span className='text-gradient'>Urkunde 2024</span> 
                  </div>
                  {/* title */}
                  <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                  <span className='text-3x1 text-white btn btn-sm '><a href='https://www.bau-sh.de/handwerker/behrend-dach-gmbh-ploen-dachdeckung-dachabdichtungsarbeiten/'>Mehr Info</a></span>
                  </div>
               </div>     
            </motion.div>
          </div>
          <div className='h-[50px]'></div>
          <div className='flex flex-col lg:flex-row gap-x-10'>
            <motion.div variants={fadeIn('right', 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}>
          <div className='group relative overflow-hidden border-2 border-black/50 rounded-xl shadow'>
                   {/*overlay*/}
                  <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                  {/* img */}
                  <img className='group-hover:scale-125 transition-all duration-500' src={News3} alt='' />
                  {/* prettitle */}
                  <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                   <span className='text-gradient'>KRANPROFI Zertifikat 2024</span> 
                  </div>
                  {/* title */}
                  
               </div>     
            </motion.div>
            <div className='h-[40px]'></div>
            <motion.div variants={fadeIn('left', 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}>
          <div className='group relative overflow-hidden border-2 border-black/50 rounded-xl shadow'>
                   {/*overlay*/}
                  <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                  {/* img */}
                  <img className='group-hover:scale-125 transition-all duration-500' src={News4} alt='' />
                  {/* prettitle */}
                  <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                   <span className='text-gradient'>Kranfüherer Zertifikat 2024</span> 
                  </div>
                  {/* title */}
                  <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                  <span className='text-3x1 text-[20px] text-white'></span>
                  </div>
               </div>     
            </motion.div>
          </div>
        </div>
        <div className='h-[100px]'></div>
    </>
  );
};

export default News;